export type Side = 'front' | 'rear';
export type LeftOrRight = 'left' | 'right';
export type Part =
  | 'hand'
  | 'foot'
  | 'ankle'
  | 'leg'
  | 'knee'
  | 'thigh'
  | 'hip'
  | 'groin'
  | 'abdomen'
  | 'rib'
  | 'wrist'
  | 'forearm'
  | 'elbow'
  | 'arm'
  | 'chest'
  | 'shoulder'
  | 'head'
  | 'neck'
  | 'upper-lower-back'
  | 'lower-back'
  | 'upper-back';

export type BodyParts = {
  [side in Side]: {
    [part in Part]?: {
      left: boolean;
      right: boolean;
    };
  };
};

export type BodyPartsTouching = {
  [side in Side]: {
    [part in Part]?: {
      left: string[];
      right: string[];
    };
  };
};

export enum SurveyQuestionIfType {
  equal = 'equal',
  lessThan = 'lessThan',
  greaterThan = 'greaterThan',
  notAnswered = 'notAnswered',
}

export type DateValueEstimate = { unit: string; value: number };
export type DateValue = { exact?: string; estimate?: DateValueEstimate };
export type DateAnswer = {
  type: 'date';
  value: DateValue;
  data?: {
    [key: string]: any;
  };
  otherValue?: string;
};

export type RadioValue = any;
export type RadioAnswer = {
  type: 'radio';
  value: RadioValue;
  otherValue?: string;
  question?: string;
  groups?: string[];
  data?: {
    [key: string]: any;
  };
};

export type CheckboxValue = string[] | number[];
export type CheckboxAnswer = {
  type: 'checkbox';
  value: CheckboxValue;
  otherValue?: string;
  data?: {
    [key: string]: any;
  };
};

export type SliderValue = number;
export type SliderAnswer = {
  type: 'slider';
  value: SliderValue;
  data?: {
    [key: string]: any;
  };
  otherValue?: string;
};

export type InputValue = string;
export type InputAnswer = {
  type: 'input';
  value: InputValue;
  data?: {
    [key: string]: any;
  };
  otherValue?: string;
};

export type TextareaValue = string;
export type TextareaAnswer = {
  type: 'textarea';
  value: TextareaValue;
  data?: {
    [key: string]: any;
  };
  otherValue?: string;
};

export type SourceOfPain = {
  side: null | 'front' | 'rear';
  x: null | number;
  y: null | number;
};

export type BodypickerAnswer = {
  type: 'bodypicker';
  value: {
    parts: BodyParts;
    sourceOfPain: SourceOfPain;
  };
  data?: {
    [key: string]: any;
  };
  otherValue?: string;
};

export type SurveyQuestionAnswerNonGroup =
  | RadioAnswer
  | CheckboxAnswer
  | DateAnswer
  | SliderAnswer
  | InputAnswer
  | TextareaAnswer
  | BodypickerAnswer;

export type GroupValue = {
  [key: string]: SurveyQuestionAnswerNonGroup;
};
export type GroupAnswer = {
  type: 'group';
  value: GroupValue;
  question?: string;
  data?: {
    [key: string]: any;
  };
  otherValue?: string;
};

export type SurveyQuestionAnswer = SurveyQuestionAnswerNonGroup | GroupAnswer;

export type SurveyOption = {
  id?: string | number;
  display: string;
  score?: number;
  data?: any;
};

export type SurveyQuestionIf = {
  type: SurveyQuestionIfType;
  value?: string | number | Array<string | number>;
  id?: string;
  demographic?: boolean;
  property?: string;
};

export type SurveyQuestionTypeOptions = {
  allowEstimate?: boolean;
  min?: number;
  max?: number;
  step?: number;
  left?: string;
  right?: string;
  notLessThan?: string | number;
  notMoreThan?: string | number;
  includeOther?: boolean;
  subQuestions?: SurveyQuestion[];
  bodyPartGroupingQuestion?: boolean;
  bodyAreaOfConcernQuestion?: boolean;
  futureOnly?: boolean;
  pastOnly?: boolean;
  data?: any;
  forceAnswer?: boolean;
  iconScale?: boolean;
  type?: string;
  start?: number;
  placeHolder?: string;
};

export type SurveyQuestion = {
  id: string;
  type: string;
  question: string;
  order?: number;
  problemArea?: number;
  complaintId?: string;
  section?: string;
  typeOptions?: SurveyQuestionTypeOptions;
  options?: SurveyOption[];
  answer?: SurveyQuestionAnswer;
  if?: SurveyQuestionIf[];
  ifSome?: SurveyQuestionIf[];
  images?: string[];
  updatedAt?: number;
};

export type Survey = {
  id: string;
  languages?: {
    [key: string]: Survey;
  };
  name: string;
  title: string;
  esTitle: string;
  esDescription?: string;
  abbreviation: string;
  subtitle: string;
  group: string;
  startMessage: string;
  endMessage: string;
  questions: SurveyQuestion[];
  scoring?: string;
  conditionReportOptions?: {
    regions?: string[];
  };
  english?: Survey;
  hideFromProd?: boolean;
  hideFromList?: boolean;
  replacedBy?: string;
};

export type SurveyProgress = {
  completed: number;
  total: number;
};

export type SurveyHealthcom = {
  id: string;
  name: string;
  title: string;
  esTitle: string;
  subtitle: string;
  startMessage: string;
  endMessage: string;
  started: boolean;
  questions?: SurveyQuestion[];
  conditionReportOptions?: {
    regions?: string[];
  };
  currentQuestion?: SurveyQuestion;
  previousQuestion?: string;
  progress: SurveyProgress;
  bodyPickerQuestions?: SurveyQuestion[];
  order?: number;
};
